.App {
  text-align: center;
}

.NoScrollBar::-webkit-scrollbar {
  display: none;
}

.dropdown {
  display: none;
}

input {
  /* width: -webkit-fill-available; */
}