/*	Reset & General
---------------------------------------------------------------------- */

#course .tile {
	width: 100%;
	background: #fff;
	border-radius: 5px;
	box-shadow: 0px 2px 3px -1px rgba(151, 171, 187, 0.7);
	float: left;
	transform-style: preserve-3d;
	margin: 10px 5px;
}

#course .header {
	border-bottom: 1px solid #ebeff2;
	padding: 19px 0;
	text-align: center;
	color: #59687f;
	font-size: 600;
	font-size: 19px;
	position: relative;
}

#course .banner-img {
	padding: 5px 5px 0;
	width: 20%;
	margin: auto;
}

#course .banner-img img {
	width: 100%;
	border-radius: 5px;
}

#course .author {
	border: 1px solid #ebeff2;
	border-radius: 5px;
	padding: 20px 0px;
	margin: 10px 20px;
	font-size: 16px;
	color: #5aadef;
	font-weight: 600;
	overflow: auto;
}
#course .author div {
	float: left;
	width: 50%;
	text-align: center;
	position: relative;
}
#course .author strong,
#course .stats strong {
	display: block;
	color: #adb8c2;
	font-size: 11px;
	font-weight: 700;
}
#course .author span {
	width: 1px;
	height: 40px;
	position: absolute;
	right: 0;
	top: 0;
	background: #ebeff2;
}
#course .stats {
	border-top: 1px solid #ebeff2;
	background: #f7f8fa;
	overflow: auto;
	padding: 15px 0;
	font-size: 16px;
	color: #59687f;
	font-weight: 600;
	border-radius: 0 0 5px 5px;
}
#course .stats div {
	border-right: 1px solid #ebeff2;
	width: 33.33333%;
	float: left;
	text-align: center;
}

#course .stats div:nth-of-type(3) {
	border: none;
}

#course div.footer {
	text-align: right;
	position: relative;
	margin: 20px 5px;
}

#course button {
	width: 100px;
	margin-left: 5px;
}
