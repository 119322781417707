@import url("http://fonts.googleapis.com/css?family=Lato:100,300,400,700,900,400italic");
@import url("//netdna.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.css");

#teacher-courses .courses-list {
	list-style: none;
	font-family: "Lato", sans-serif;
	margin: 0px;
	padding: 0px;
	margin: auto;
}
#teacher-courses .courses-list > li {
	background-color: rgb(255, 255, 255);
	box-shadow: 0px 0px 5px rgb(51, 51, 51);
	box-shadow: 0px 0px 5px rgba(51, 51, 51, 0.7);
	padding: 0px;
	margin: 0px 0px 20px;
}
#teacher-courses .courses-list > li > time {
	display: inline-block;
	width: 100%;
	color: rgb(255, 255, 255);
	background-color: rgb(197, 44, 102);
	padding: 5px;
	text-align: center;
	text-transform: uppercase;
}
#teacher-courses .courses-list > li:nth-child(even) > time {
	background-color: rgb(165, 82, 167);
}
#teacher-courses .courses-list > li > time > span {
	display: none;
}
#teacher-courses .courses-list > li > time > .day {
	display: block;
	font-size: 56pt;
	font-weight: 100;
	line-height: 1;
}
#teacher-courses .courses-list > li time > .month {
	display: block;
	font-size: 24pt;
	font-weight: 900;
	line-height: 1;
}
#teacher-courses .courses-list > li > img {
	width: 100%;
}
#teacher-courses .courses-list > li > .info {
	padding-top: 5px;
	text-align: center;
}
#teacher-courses .courses-list > li > .info > .title {
	font-size: 17pt;
	font-weight: 700;
	margin: 0px;
}
#teacher-courses .courses-list > li > .info > .desc {
	font-size: 13pt;
	font-weight: 300;
	margin: 0px;
}
#teacher-courses .courses-list > li > .info > ul,
#teacher-courses .courses-list > li > .social > ul {
	display: table;
	list-style: none;
	margin: 10px 0px 0px;
	padding: 0px;
	width: 100%;
	text-align: center;
}
#teacher-courses .courses-list > li > .social > ul {
	margin: 0px;
}
#teacher-courses .courses-list > li > .info > ul > li,
#teacher-courses .courses-list > li > .social > ul > li {
	display: table-cell;
	cursor: pointer;
	color: rgb(30, 30, 30);
	font-size: 11pt;
	font-weight: 300;
	padding: 3px 0px;
}
#teacher-courses .courses-list > li > .info > ul > li > a {
	display: block;
	width: 100%;
	color: rgb(30, 30, 30);
	text-decoration: none;
}
#teacher-courses .courses-list > li > .social > ul > li {
	padding: 0px;
}
#teacher-courses .courses-list > li > .social > ul > li > a {
	padding: 3px 0px;
}
#teacher-courses .courses-list > li > .info > ul > li:hover,
#teacher-courses .courses-list > li > .social > ul > li:hover {
	color: rgb(30, 30, 30);
	background-color: rgb(200, 200, 200);
}

@media (min-width: 768px) {
	#teacher-courses .courses-list > li {
		position: relative;
		display: block;
		width: 100%;
		height: 150px;
		padding: 0px;
	}
	#teacher-courses .courses-list > li > time,
	#teacher-courses .courses-list > li > img {
		display: inline-block;
	}
	#teacher-courses .courses-list > li > time,
	#teacher-courses .courses-list > li > img {
		width: 120px;
		float: left;
	}
	#teacher-courses .courses-list > li > .info {
		background-color: rgb(245, 245, 245);
		overflow: hidden;
	}
	#teacher-courses .courses-list > li > time,
	#teacher-courses .courses-list > li > img {
		width: 120px;
		height: 150px;
		padding: 0px;
		margin: 0px;
	}
	#teacher-courses .courses-list > li > .info {
		position: relative;
		height: 150px;
		text-align: left;
		padding-right: 40px;
	}
	#teacher-courses .courses-list > li > .info > .title,
	#teacher-courses .courses-list > li > .info > .desc {
		padding: 0px 10px;
	}
	#teacher-courses .courses-list > li > .info > ul {
		position: absolute;
		left: 0px;
		bottom: 0px;
	}
	#teacher-courses .courses-list > li > .social {
		position: absolute;
		top: 0px;
		right: 0px;
		display: block;
		width: 40px;
	}
	#teacher-courses .courses-list > li > .social > ul {
		border-left: 1px solid rgb(230, 230, 230);
	}
	#teacher-courses .courses-list > li > .social > ul > li {
		display: block;
		padding: 0px;
	}
	#teacher-courses .courses-list > li > .social > ul > li > a {
		display: block;
		width: 40px;
		padding: 10px 0px 9px;
	}
}
