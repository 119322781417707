/* heading */

#new-invoice h1 {
	font: bold 100% sans-serif;
	letter-spacing: 0.5em;
	text-align: center;
	text-transform: uppercase;
}

/* table */

#new-invoice table {
	font-size: 75%;
	table-layout: fixed;
	width: 100%;
}
#new-invoice table {
	border-collapse: separate;
	border-spacing: 2px;
}
#new-invoice th,
#new-invoice td {
	border-width: 1px;
	padding: 0.5em;
	position: relative;
	text-align: left;
}
#new-invoice th,
#new-invoice td {
	border-radius: 0.25em;
	border-style: solid;
}
#new-invoice th {
	background: #eee;
	border-color: #bbb;
}
#new-invoice #new-invoice td {
	border-color: #ddd;
}

/* header */

#new-invoice header {
	margin: 0 0 3em;
}
#new-invoice header:after {
	clear: both;
	content: "";
	display: table;
}

#new-invoice header h1 {
	background: #019ce4;
	border-radius: 0.25em;
	color: #fff;
	margin: 0 0 1em;
	padding: 0.5em 0;
}
#new-invoice header address {
	float: left;
	font-size: 95%;
	font-style: normal;
	line-height: 1.25;
	margin: 0 1em 1em 0;
}
#new-invoice article address.norm h4 {
	font-size: 125%;
	font-weight: bold;
}
#new-invoice article address.norm {
	float: left;
	font-size: 95%;
	font-style: normal;
	font-weight: normal;
	line-height: 1.25;
	margin: 0 1em 1em 0;
}
#new-invoice header address p {
	margin: 0 0 0.25em;
}
#new-invoice header span,
#new-invoice header img {
	display: block;
	float: right;
}
#new-invoice header span {
	margin: 0 0 1em 1em;
	max-height: 25%;
	max-width: 60%;
	position: relative;
}
#new-invoice header img {
	max-height: 100%;

	max-width: 100%;
	width: 100px;
	height: auto;
}
#new-invoice header input {
	cursor: pointer;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	height: 100%;
	left: 0;
	opacity: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

/* article */

#new-invoice article,
#new-invoice article address,
#new-invoice table.meta,
#new-invoice table.inventory {
	margin: 0 0 3em;
}
#new-invoice article:after {
	clear: both;
	content: "";
	display: table;
}
#new-invoice article h1 {
	clip: rect(0 0 0 0);
	position: absolute;
}

#new-invoice article address {
	float: left;
	font-size: 125%;
	font-weight: bold;
}

/* table meta & balance */

#new-invoice table.meta,
#new-invoice table.balance {
	float: right;
	width: 36%;
}
#new-invoice table.meta:after,
#new-invoice table.balance:after {
	clear: both;
	content: "";
	display: table;
}

/* table meta */

#new-invoice table.meta th {
	width: 40%;
}
#new-invoice table.meta td {
	width: 60%;
}

/* table items */

#new-invoice table.inventory {
	clear: both;
	width: 100%;
}
#new-invoice table.inventory th:first-child {
	width: 50px;
}
#new-invoice table.inventory th:nth-child(2) {
	width: 200px;
}
#new-invoice table.inventory th:nth-child(3) {
	width: 900px;
}
#new-invoice table.inventory th {
	font-weight: bold;
	text-align: center;
}

#new-invoice table.inventory td:nth-child(1) {
	width: 26%;
}
#new-invoice table.inventory td:nth-child(2) {
	width: 38%;
}
#new-invoice table.inventory td:nth-child(3) {
	text-align: left;
	width: 12%;
}
#new-invoice table.inventory td:nth-child(4) {
	text-align: left;
	width: 12%;
}
#new-invoice table.inventory td:nth-child(5) {
	text-align: left;
	width: 12%;
}

/* table balance */

#new-invoice table.balance th,
#new-invoice table.balance td {
	width: 50%;
}
#new-invoice table.balance td {
	text-align: right;
}

/* aside */

#new-invoice aside h1 {
	border: none;
	border-width: 0 0 1px;
	margin: 0 0 1em;
}
#new-invoice aside h1 {
	border-color: #999;
	border-bottom-style: solid;
}

#new-invoice table.sign {
	float: left;
	width: 220px;
}
#new-invoice table.sign img {
	width: 100%;
}
#new-invoice table.sign tr td {
	border-color: transparent;
}

#new-invoice button {
	width: 150px;
}
#new-invoice button:first-of-type {
	margin-bottom: 5px;
}

#new-invoice form {
	display: block !important;
}

#new-invoice input {
	width: 100%;
}

#new-invoice #recipient {
	/* float: left; */
	position: relative;
	margin-right: 95%;
}

#new-invoice #add-button-container,
#new-invoice #remove-button-container {
	border: none;
}

#new-invoice .spinner-border-container {
	margin: auto;
}

@media print {
	#new-invoice > * {
		-webkit-print-color-adjust: exact;
	}
	#new-invoice html {
		background: none;
		padding: 0;
	}
	#new-invoice body {
		box-shadow: none;
		margin: 0;
	}
	#new-invoice span:empty {
		display: none;
	}
	#new-invoice .add,
	#new-invoice .cut {
		display: none;
	}
}

@page {
	margin: 0;
}
/*  */
